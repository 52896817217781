import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@tanstack/react-location'
import { usePageTitle } from '../store'
import { isClient } from '../../_utils'

export default function Helm() {
  const {
    config: { title, description },
  } = usePageTitle()
  const pathname = useLocation().current.pathname
  const prefix = import.meta.env.PUBLIC_VITE_APP_TITLE_PREFIX
  const { t } = useTranslation()
  const pageDescription = description || t('pageTitle.home')
  const url = `https://zawody.agilix.dog/${pathname}`

  return (
    <Helmet>
      <title>{title ? `${prefix}${title} | Agilix🐾` : `${prefix}Agilix`}</title>
      {isClient ? <link rel="canonical" href={window.location.href} /> : null}
      <meta name="description" content={pageDescription} />
      <meta property="og:image" content="https://zawody.agilix.dog/agility-og.png" />
      <meta property="al:web:should_fallback" content="false" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={pageDescription} />

      <meta property="fb:app_id" content="1679997035493879" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="zawody.agilix.dog" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content="https://zawody.agilix.dog/agility-og.png" />
    </Helmet>
  )
}
