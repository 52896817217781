import { Outlet } from '@tanstack/react-location'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { cookieKeys } from '../_constants'
import { Context } from '../_types'
import { getBrowserCookie, isClient } from '../_utils'
import { WithClientApp } from '../_layout/withClientApp.tsx'
import ErrorBoundary from './components/errorBoundary.tsx'
import Helm from './components/helm.tsx'
import Home from './components/icons/home.tsx'
import ChevronRight from './components/icons/chevronRight.tsx'
import { pageTitleId } from './constants'
import { useRefreshWhoami, UserTokenProvider, useUpdateMyPlatformPresence } from './utils/hooks.tsx'
import { useAuth, usePageTitle } from './store'
import { userLocaleStore } from './translations/i18nUtils.tsx'

const ClientLayout = ({
  stewartMeta,
}: {
  stewartMeta: { key: string; competitionId: string } | null
}) => {
  useUpdateMyPlatformPresence()
  useRefreshWhoami()
  const {
    config: { title, crumbs },
  } = usePageTitle()

  return (
    <div>
      {stewartMeta ? (
        <>
          <div className="z-1 p-2" data-cy="stewart-meta-view">
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <Helm />
          <div
            className="mt-4 md:mt-10 mb-2 min-h-[24px] flex flex-col items-start gap-4 sm:mb-0 sm:flex-row sm:items-center sm:gap-0"
            data-cy="desktop-nav">
            {!crumbs
              ? Array.from({ length: 3 }).map((item, index) => (
                  <div key={index + String(item)} className="text-transparent" />
                ))
              : crumbs.map((crumb, index) => (
                  <div className="flex items-center gap-2" key={crumb.name + crumb.url}>
                    {index === 0 ? (
                      <div className="mr-2">
                        <a href="/" aria-label="home">
                          <div className="underline">
                            <Home />
                          </div>
                        </a>
                      </div>
                    ) : (
                      <ChevronRight className="ml-0 h-3 w-3 md:ml-2" />
                    )}
                    <div>
                      {index === crumbs?.length - 1 ? (
                        <div>{crumb.name}</div>
                      ) : (
                        <a href={crumb.url} aria-label={crumb.name}>
                          <div className="underline">{crumb.name} </div>
                        </a>
                      )}
                    </div>
                  </div>
                ))}
          </div>

          {title ? (
            <h2 data-cy={title} className="my-5 min-h-[30px] p-0 text-2xl" id={pageTitleId}>
              {title}
            </h2>
          ) : (
            <div className="py-2" />
          )}

          <Outlet />
        </>
      )}
    </div>
  )
}

export default function Main({
  context,
  stewartMeta,
}: {
  context: Context
  stewartMeta: { key: string; competitionId: string } | null
}) {
  const [stateToken, setStateToken] = useState<undefined | string>(
    isClient ? getBrowserCookie(cookieKeys.token) || undefined : context.token || undefined,
  )

  const { token } = useAuth()

  useEffect(() => {
    setStateToken(token)
  }, [token])

  userLocaleStore.set(context.locale)

  return (
    <UserTokenProvider.Provider value={stateToken}>
      <ErrorBoundary>
        <I18nextProvider i18n={i18next}>
          <WithClientApp fallback={<div />}>
            <HelmetProvider>
              <ClientLayout stewartMeta={stewartMeta} />
            </HelmetProvider>
          </WithClientApp>
        </I18nextProvider>
      </ErrorBoundary>
    </UserTokenProvider.Provider>
  )
}
